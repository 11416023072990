class Common {
	
	constructor () {
		this.window = $(window);

		this.window.resize(() => { this.resize() } );
		this.window.scroll(() => { this.scroll() } );

		this.resize();
	}

	resize () {
		this.scroll();
	}

	scroll () {
		this.window.w = this.window.width();
		this.window.h = this.window.height();
		this.window.x = this.window.scrollLeft();
		this.window.y = this.window.scrollTop();

		$('.scrollanimation').each((index, item) => {
			var enterline = $(item).data('enterline') || 'center';
			var baseline = $(item).data('baseline') || 'center';
			var reverse = $(item).data('reverse') || '';
			var offset = parseInt($(item).data('offset') || 0);
			var Y = 0;
			var y = 0;

			if(enterline == 'top') {
				Y = this.window.y;
			}
			if(enterline == 'center') {
				Y = this.window.y + this.window.h / 2;
			}
			if(enterline == 'bottom') {
				Y = this.window.y + this.window.h;
			}
			if(baseline == 'top') {
				y = $(item).offset().top;
			}
			if(baseline == 'center') {
				y = $(item).offset().top + $(item).outerHeight() / 2;
			}
			if(baseline == 'bottom') {
				y = $(item).offset().top + $(item).outerHeight();
			}
			if(Y > y + offset) {
				$(item).addClass('active');
			} else if(reverse) {
				$(item).removeClass('active');
			}
		});
	}
}

window.common = new Common();